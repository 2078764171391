import {
    useState,
    useRef,
    useEffect
  } from 'react';
  
  export const useTimeoutMessage = (initialValue, timeout = 3000) => {
    const timeoutRef = useRef(null);
    const [message, setMessage] = useState(initialValue);
  
    useEffect(() => {
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);
  
    return [
      message, data => {
        clearTimeout(timeoutRef.current);
        setMessage(data);
        timeoutRef.current = setTimeout(() => setMessage(false), timeout);
      }
    ];
  };
  