import React, { useEffect, useState } from 'react';
import { weightOptions, dosageOptions } from '../constants';
import Section from './Section';
import ResultRenderer from './ResultRenderer';
import '../style/App.css';
import { useTimeoutMessage } from '../hooks/useTimeoutMessage';

const App = () => {
    const [weight, setWeight] = useState(weightOptions[0].value);
    const [weightInput, setWeightInput] = useState();
    const [dosage, setDosage] = useState(dosageOptions[0].value);
    const [dosageInput, setDosageInput] = useState();

    const resultOptions = [...weightOptions, ...dosageOptions]

    const [calculation, setCalculation] = useState(resultOptions[0].value);
    const [calculationInput, setCalculationInput] = useState();

    const [results, setResults] = useState({});

    const [errorMessage, setErrorMessage] = useTimeoutMessage(false, 2000);

    const calculateDosage = () => {

        if (!weightInput || !dosageInput || !calculationInput) {
            setResults({});
            setErrorMessage({ text: 'Lütfen değerleri boş bırakmayın' });
            return;
        }

        setErrorMessage();

        let _k = parseFloat(weightInput) / parseFloat(dosageInput);
        if (dosage === 'dizyem') {
            _k = _k / 10;
        }
        if (dosage === 'mililitre') {
            _k = _k / 100;
        }
        if (weight === 'miligram') {
            _k = _k * 1000;
        }
        if (weight === 'gram') {
            _k = _k * 1000000;
        }

        const enteredDosage = parseFloat(calculationInput);
        const selectedBirim = calculation;

        let uniteResult = 0;
        switch (selectedBirim) {
            case 'unite':
                uniteResult = enteredDosage;
                break;
            case 'dizyem':
                uniteResult = enteredDosage / 10;
                break;
            case 'mililitre':
                uniteResult = enteredDosage / 100;
                break;
            case 'mikrogram':
                uniteResult = enteredDosage / _k;
                break;
            case 'miligram':
                uniteResult = (enteredDosage / _k) * 1000;
                break;
            case 'gram':
                uniteResult = (enteredDosage / _k) * 1000000;
                break;
            default:
                uniteResult = 0;
        }
        let mikrogramResult = uniteResult * _k;
        let dizyemResult = uniteResult / 10;
        let mililitreResult = uniteResult / 100;
        let miligramResult = mikrogramResult / 1000;
        let gramResult = mikrogramResult / 1000000;
        setResults({
            dosageResults: [
                { text: 'Mililitre', value: 'mililitre', result: mililitreResult },
                { text: 'Dizyem', value: 'dizyem', result: dizyemResult },
                { text: 'Ünite', value: 'unite', result: uniteResult },
            ],
            weightResults: [
                { text: 'Gram', value: 'gram', result: gramResult },
                { text: 'Miligram', value: 'miligram', result: miligramResult },
                { text: 'Mikrogram', value: 'mikrogram', result: mikrogramResult },
            ]
        });
    }

    useEffect(() => {
        if (results.length > 0) { // eslint-disable-next-line react-hooks/exhaustive-deps
            setResults({});
        }
    }, [weight, weightInput, dosage, dosageInput, calculation, calculationInput])

    //const {mililitreResult, dizyemResult, uniteResult, gramResult, miligramResult, mikrogramResult} = result;

    return (
        <div className='app-wrapper'>
            <Section
                options={weightOptions}
                sectionText='Ağırlık Seçimi'
                sectionInputText='Ağırlık Miktarını Giriniz'
                selectedOption={weight}
                setSelectedOption={setWeight}
                setInputValue={setWeightInput}
            />
            <Section
                options={dosageOptions}
                sectionText='Doz Seçimi'
                sectionInputText='Doz Miktarını Giriniz'
                selectedOption={dosage}
                setSelectedOption={setDosage}
                setInputValue={setDosageInput}
            />
            <Section
                options={resultOptions}
                sectionText='Hangi Değeri Hesaplamak istersiniz'
                sectionInputText='Miktarı giriniz'
                selectedOption={calculation}
                setSelectedOption={setCalculation}
                setInputValue={setCalculationInput}
            />
            <div className='button-wrapper'>
                <button className='calculate-result'
                    type='button'
                    onClick={calculateDosage}
                >
                    Hesapla
                </button>
                <span>{`Sudiş için yapılmıştır :) ❤️`}</span>
            </div>

            {errorMessage && (
                <h3 className='error-message'> {errorMessage.text}</h3>
            )}
            {Object.keys(results).length > 0 && (
                <ResultRenderer
                    results={results}
                    selectedCalculation={calculation}
                />
            )}
        </div>
    )
}

export default App;