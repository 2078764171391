export const weightOptions = [
    {
        text: 'Gram',
        value: 'gram'
    },
    {
        text:'Miligram',
        value:'miligram'
    },
    {
        text: 'Mikrogram',
        value:'mikrogram'
    }
]
    

export const dosageOptions = [
    {
        text: 'Mililitre',
        value: 'mililitre'
    },
    {
        text:'Dizyem',
        value:'dizyem'
    },
    {
        text: 'Ünite',
        value:'unite'
    }
]