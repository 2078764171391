import React from 'react';

const Section = ({
    options,
    sectionText,
    sectionInputText,
    selectedOption,
    setSelectedOption,
    setInputValue
}) => {

    const handleDropdownChange = (e) => {
        setSelectedOption(e.target.value);
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    }

    return (
        <div className='section-wrapper'>
            <span>{sectionText}</span>
            <div className='selection-wrapper'>
                <select
                    value={selectedOption}
                    onChange={handleDropdownChange}
                >
                    {options.map(option => (
                        <option value={option.value}>{option.text}</option>
                    ))}
                </select>
                <input type='number'
                    placeholder={sectionInputText}
                    onChange={handleInputChange}
                >
                </input>
            </div>
        </div>
    )
}

export default Section;