import React from 'react';

const ResultRenderer = ({
    results,
    selectedCalculation
}) => {
    const { dosageResults, weightResults } = results;

    return (
        <div className='result-wrapper'>
            <div className='weight-result sup-wrapper'>
                <h2>Ağırlık Sonuçları</h2>
                {weightResults.map(result => {
                    // const isSelectedOne = result.value === selectedCalculation;
                     return (
                        <span className={`result-wrapper${result.value === selectedCalculation ? ' selected' : ''}`}>
                             {result.text} = {result.result}
                        </span>
                    )
                })}
                
               
            </div>
            <div className='dosage-result sup-wrapper'>
                <h2>Doz Sonuçları</h2>
                {dosageResults.map(result => {
                    // const isSelectedOne = result.value === selectedCalculation;
                     return (
                        <span className={`result-wrapper${result.value === selectedCalculation ? ' selected' : ''}`}>
                             {result.text} = {result.result}
                        </span>
                    )
                })}
            </div>

        </div>
    )
}

export default ResultRenderer;